import PasswordSVG from '~shared/components/Icons/svg/password.svg'
import GoogleSVG from '~shared/components/Svgs/google.svg'
import MicrosoftSVG from '~shared/components/Svgs/microsoft.svg'
import { APP_ENV } from '~shared/config/app'

export const SSO_ENABLED = APP_ENV !== 'production'

// This enum should map to BE `sso_providers.id` in the `persona` service
export enum SSOProvider {
  Password = 'password',
  Google = 'google',
  Microsoft = 'microsoft',
}

export const isSSOProvider = (value: string): value is SSOProvider =>
  Object.values(SSOProvider).includes(value as SSOProvider)

export type SSOProviderInfo = {
  title: string
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

export const getSSOProviderInformation = (
  provider: SSOProvider,
): SSOProviderInfo => {
  switch (provider) {
    case SSOProvider.Password:
      return { title: 'Email & Password', Icon: PasswordSVG }
    case SSOProvider.Google:
      return { title: 'Google', Icon: GoogleSVG }
    case SSOProvider.Microsoft:
      return { title: 'Microsoft', Icon: MicrosoftSVG }
  }
}
