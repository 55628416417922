import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions

export const hasActiveOnboardingConfigurations = (
  options?: Options,
): MiddlewareHandler => ({
  fn: async ({ mainContractor }) => {
    const hasActiveOnboardingConfigurations = (
      mainContractor?.onboardingConfigurations || []
    ).some((config) => config.active)

    return mainContractor?.status === 'ACTIVE' ||
      hasActiveOnboardingConfigurations
      ? { status: 'ok' }
      : { status: 'redirect' }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: undefined,
})
