import {
  CAN_VIEW_DEBUG,
  CAN_VIEW_FEATURE_FLAG,
  CAN_VIEW_INCIDENT,
  CAN_VIEW_NETWORKS,
  CAN_VIEW_PAYMENTS,
  CAN_VIEW_SCHEDULING,
} from '~shared/hooks/gatekeeper/checks'

import M from './middlewares/alias'
import { MiddlewareHandlerV2, MiddlewareMap } from './types'

// Helper to make creating v2 middlewares easier
const v2 = (
  config: Omit<MiddlewareHandlerV2, 'type'>,
): MiddlewareHandlerV2 => ({
  type: 'v2',
  ...config,
})

export const APPLICATION_MIDDLEWARE_MAP: MiddlewareMap = {
  // NAP routes
  '/resource/direct/available': v2({ NAP: [] }),
  '/resource/direct/onboarding': v2({ NAP: [] }),
  '/resource/direct/offboarded': v2({ NAP: [] }),
  '/resource/partners/available': v2({ NAP: [] }),
  '/resource/partners/onboarding': v2({ NAP: [] }),
  '/resource/partners/disengaged': v2({ NAP: [] }),

  '/settings/onboarding-configurations': v2({}),
  '/settings/partner-subcontractor-requirements': v2({ NAP: [] }),
  '/settings/documents': v2({ NAP: [] }),
  '/settings/documents/[documentId]': v2({ NAP: [] }),

  // WAP routes
  '/customers': [M.WAP, M.Permissions.Wise.Customer.List],
  '/customers/[id]': [M.WAP, M.Permissions.Wise.Customer.Get],
  '/debug/app-config': [M.WAP, M.gatekeeper({ check: CAN_VIEW_FEATURE_FLAG })],
  '/debug/dev': [M.WAP, M.gatekeeper({ check: CAN_VIEW_DEBUG })],
  '/debug/feature-flags': [
    M.WAP,
    M.gatekeeper({ check: CAN_VIEW_FEATURE_FLAG }),
  ],
  '/debug/maintenance': [M.WAP, M.gatekeeper({ check: CAN_VIEW_DEBUG })],
  '/evidence-template/labels': M.WAP,
  '/evidence-template/new': M.WAP,
  '/evidence-template/edit/[id]': M.WAP,
  '/evidence-template/templates': M.WAP,
  '/main-contractors': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/onboarding': [
    M.WAP,
    M.Permissions.Wise.MainContractor.List,
  ],
  '/main-contractors/archived': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/inactive': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/invite': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/[mcId]': [M.WAP, M.Permissions.Wise.MainContractor.Get],
  '/main-contractors/[mcId]/driving-licence-checks': [
    M.WAP,
    M.FeatureFlag.DrivingLicenceChecksEnabled,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/depots': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/document-library': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/document-library/uploaded': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/document-library/archived': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/documents/[documentId]': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/bulk-upload': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.BulkUploadEnabled,
  ],
  '/main-contractors/[mcId]/onboarding': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/rate-cards': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/main-contractors/[mcId]/rate-cards/configurator': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/main-contractors/[mcId]/carrier-settings': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/main-contractors/[mcId]/payments': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/payments/details': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/payments/payment-caps': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.DeductionsEnabled,
    M.MCHasDeductionsEnabled.Default,
  ],
  '/main-contractors/[mcId]/users': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/sso': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.SSOEnabled,
  ],
  '/networks': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/bulk-upload': v2({
    WAP: [
      M.gatekeeper({ check: CAN_VIEW_NETWORKS }),
      M.FeatureFlag.BulkUploadEnabled,
    ],
  }),
  '/networks/[networkId]/documents': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/documents/[documentId]': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/onboarding-configurations': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/partner-subcontractor-requirements': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/payments': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/payments/details': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/payments/payment-caps': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/users': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS })],
  }),
  '/networks/[networkId]/sso': v2({
    WAP: [M.gatekeeper({ check: CAN_VIEW_NETWORKS }), M.FeatureFlag.SSOEnabled],
  }),
  '/payments/[mcId]/[depotId]': [
    M.WAP,
    M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
    M.Modulr.Default,
  ],
  '/payments/closed': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/payments/completed': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/payments/in-progress': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/payments/submitted': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/users': M.WAP,

  // MCP or NAP routes
  '/payments/depot/[depotId]': v2({
    MCP: [
      M.MainContractor.Active,
      M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
      M.Modulr.Default,
    ],
    NAP: [
      M.FeatureFlag.NetworkPaymentsEnabled,
      M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
      M.Modulr.Default,
    ],
  }),
  '/settings': v2({
    MCP: [
      M.Permissions.Own.MainContractor.Get,
      M.MCOnboarding.HasUploadedSignature,
    ],
    NAP: [],
  }),
  '/settings/bulk-upload': v2({
    MCP: [
      M.MCOnboarding.HasActiveOnboardingConfigurations,
      M.FeatureFlag.BulkUploadEnabled,
    ],
    NAP: [M.FeatureFlag.BulkUploadEnabled],
  }),
  '/settings/users': v2({
    MCP: [M.Permissions.User.List, M.MCOnboarding.HasUploadedSignature],
    NAP: [],
  }),
  '/settings/payments': [M.MCP, M.MainContractor.Active],
  '/settings/payment-caps': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.DeductionsEnabled,
    M.MCHasDeductionsEnabled.Default,
  ],
  '/settings/rate-cards': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/settings/carrier-settings': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/settings/driving-licence-checks': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.DrivingLicenceChecksEnabled,
  ],

  //MCP-onboarding-only routes
  '/settings/document-library': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.HasUploadedSignature,
  ],

  // Authenticated routes (MCP or WAP)
  '/': [M.Authenticated, M.MCOnboarding.HasUploadedSignature],
  '/compliance/evidence': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/compliance/driving-licence': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
    M.FeatureFlag.DrivingLicenceChecksEnabled,
  ],
  '/drivers/[id]': [M.Authenticated, M.MainContractor.Active],
  '/drivers/[id]/vehicle': [M.Authenticated, M.MainContractor.Active],
  '/drivers/[id]/engagement-details': v2({
    MCP: [M.MainContractor.Active],
    NAP: [],
  }),
  '/drivers/[id]/engagements/main-contractor/[mcId]': [M.Authenticated, M.WAP],
  '/drivers/[id]/engagements/network/[networkId]': [M.Authenticated, M.WAP],
  '/drivers/[id]/financial-details': [M.Authenticated, M.MainContractor.Active],
  '/drivers/[id]/incidents': [
    M.Authenticated,
    M.gatekeeper({ check: CAN_VIEW_INCIDENT }),
    M.MainContractor.Active,
    M.FeatureFlag.IncidentsEnabled,
    M.MCHasDeductionsEnabled.AllowedOnWap,
  ],
  '/drivers/[id]/payments/[summaryId]': v2({
    MCP: [M.MainContractor.Active, M.Modulr.Default],
    WAP: [],
    NAP: [M.FeatureFlag.NetworkPaymentsEnabled, M.Modulr.Default],
  }),
  '/drivers/[id]/payments/create/[payrunId]': v2({
    MCP: [M.MainContractor.Active, M.Modulr.Default],
    WAP: [],
    NAP: [M.FeatureFlag.NetworkPaymentsEnabled, M.Modulr.Default],
  }),
  '/drivers/active': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/drivers/inactive': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/drivers/onboarding': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/knowledge-base': [M.Authenticated, M.MainContractor.Active],
  '/knowledge-base/[...category]': [M.Authenticated, M.MainContractor.Active],
  '/payments': v2({
    MCP: [
      M.MainContractor.Active,
      M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
      M.Modulr.Default,
    ],
    WAP: [M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
    NAP: [
      M.FeatureFlag.NetworkPaymentsEnabled,
      M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
      M.Modulr.Default,
    ],
  }),
  '/payments/payrun/[id]': [
    M.Authenticated,
    M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
  ],
  '/reports': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/awaiting-activation': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/driver-depots': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/signed-policies': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/slas': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/unresponsive-applications': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/utr-report': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/deductions-overview': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
    M.FeatureFlag.DeductionsEnabled,
    M.MCHasDeductionsEnabled.AllowedOnWap,
  ],
  '/reports/payable-drivers': [
    M.WAP,
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/scheduling': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],
  '/scheduling/[mcId]/[depotId]': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],
  '/scheduling/route-library/[mcId]/[depotId]': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],
  '/scheduling/[mcId]/[depotId]/start-times': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],

  /**
   * Main Contractor Onboarding Tool routes
   */

  // Group 1 - Unauthenticated, needs code
  '/onboarding/welcome': [M.Guest, M.MCOnboarding.Stages.CreateAccount],
  '/onboarding/create-account': [M.Guest, M.MCOnboarding.Stages.CreateAccount],

  // Group 2 - Authenticated, needs MC Onboarding Data but no mainContractor
  '/onboarding/about-wise': [M.MCP, M.MCOnboarding.Stages.SetupCompany],
  '/onboarding/about-your-business': [
    M.MCP,
    M.MCOnboarding.Stages.SetupCompany,
  ],

  // Group 3 - Authenticated, needs MC onboarding, MC, but no contracts or signature
  '/onboarding/protecting-your-business': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.Stages.Contracts,
  ],
  '/onboarding/contracts-and-policies': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.Stages.Contracts,
  ],

  '/payments/kyc': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.HasUploadedSignature,
  ],

  // Worklog routes
  '/worklog': [
    M.Authenticated,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/worklog/[id]/[locationId]/history': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/worklog/[id]/history': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],

  // Guest routes
  '/auth/verify': M.Guest,
  '/forgot-password': M.Guest,
  '/login': M.Guest,

  // Unprotected routes
  '/404': null,
  '/auth/action': null,
  '/logout': null,
  '/auth/reset-password': null,
  '/auth/change-password': null,
}
