import { invariant, isDefined, isNonEmptyString } from '@wise/utils'
import { castArray } from 'lodash'
import Router, { useRouter } from 'next/router'
import * as React from 'react'
import create from 'zustand'

import { RoutePaths } from '~generated/routes'
import { useMode } from '~shared/hooks/useMode'
import M from '~shared/middleware/middlewares/alias'
import { parsePermission } from '~shared/permissions/permissions'
import { leaveBreadcrumb, notify } from '~shared/services/bugsnag/client'
import { useAuth } from '~shared/services/firebase/auth/hooks'

import {
  getMainContractorFromUser,
  useMaincontractor,
} from '@/maincontractors/hooks/useMaincontractor'
import { useModulrPayments } from '@/payments/hooks/useModulrPayments'
import { usePlatform } from '@/platforms/hooks/usePlatform'

import { APPLICATION_MIDDLEWARE_MAP } from './map'
import { getNextRoute } from './nextRoute'
import {
  MiddlewareHandler,
  MiddlewarePayload,
  MiddlewareResponse,
  MiddlewareStatus,
  MiddlewareWrapper,
  isMiddlewareHandlerV2,
} from './types'

type Props = {
  children: React.ReactNode
}

const resolveMiddleware = async (
  middleware: MiddlewareHandler | MiddlewareHandler[],
  payload: MiddlewarePayload,
): Promise<MiddlewareResponse> => {
  const list = Array.isArray(middleware) ? middleware : [middleware]
  for (const item of list) {
    let result = item.fn(payload)
    if (result instanceof Promise) {
      result = await result
    }
    if (result.status !== 'ok') return result
  }
  return { status: 'ok' }
}

const MiddlewareContext = React.createContext<MiddlewarePayload | null>(null)

export const useMiddlewareContext = (): MiddlewarePayload => {
  const payload = React.useContext(MiddlewareContext)
  invariant(isDefined(payload), 'MiddlewareContext is not defined')
  return payload
}

type MiddlewareState = {
  pathname: string
  asPath: string
  result: MiddlewareStatus['status']

  set: (o: Pick<MiddlewareState, 'pathname' | 'asPath' | 'result'>) => void
}

const useMiddlewareState = create<MiddlewareState>((set) => ({
  pathname: '',
  asPath: '',
  result: 'loading',

  set: ({ pathname, asPath, result }) => set({ pathname, asPath, result }),
}))

export const MiddlewareProvider = ({ children }: Props) => {
  const auth = useAuth()
  const mode = useMode()
  const router = useRouter()
  const mc = useMaincontractor()
  const platform = usePlatform()
  const mainContractorId = React.useMemo(() => {
    const possibleIds = [mc?.id, router.query.mcId]
    const id = possibleIds.find(isNonEmptyString)
    return id
  }, [mc?.id, router.query.mcId])
  const modulrInfo = useModulrPayments({ platform })

  const middlewarePayload = React.useMemo<MiddlewarePayload>(() => {
    const user = auth.data?.user
    const permissions =
      user?.permissions?.filter(isDefined).map(parsePermission) ?? []

    const mainContractor = getMainContractorFromUser(user)

    return {
      auth,
      mode,
      permissions,
      mainContractor,
      modulrInfo,
      mainContractorId,
    }
  }, [auth, mainContractorId, mode, modulrInfo])

  const middlewarePayloadRef = React.useRef(middlewarePayload)
  React.useEffect(() => {
    middlewarePayloadRef.current = middlewarePayload
  }, [middlewarePayload])

  const middlewares = React.useMemo(() => {
    const result =
      APPLICATION_MIDDLEWARE_MAP[router.pathname as RoutePaths] ?? []

    // Handle v2 middleware and resolve to the standard middlewares
    if (isMiddlewareHandlerV2(result)) {
      const runtimeMiddlewares = result[mode]
      // If there is no defined middleware for this runtime, default to not-allowed middleware
      if (runtimeMiddlewares === undefined) return [M.NotAllowed]
      return castArray(runtimeMiddlewares)
    }

    return castArray(result)
  }, [router.pathname, mode])

  React.useEffect(() => {
    const asPath = router.asPath
    const pathname = router.pathname

    if (!auth.resolved)
      return useMiddlewareState
        .getState()
        .set({ asPath, pathname, result: 'loading' })

    const middlewarePayload = middlewarePayloadRef.current

    if (middlewares.length === 0)
      return useMiddlewareState
        .getState()
        .set({ asPath, pathname, result: 'ok' })

    const state = useMiddlewareState.getState()
    if (router.pathname === state.pathname && state.result !== 'loading') return

    const fn = async () => {
      try {
        useMiddlewareState
          .getState()
          .set({ asPath, pathname, result: 'loading' })
        const result = await resolveMiddleware(middlewares, middlewarePayload)

        // Ensure the path hasn't changed in the meantime
        if (asPath !== Router.asPath) return

        // If we have a redirect result, we need to handle it now
        if (result.status === 'redirect') {
          const next =
            result.url ||
            getNextRoute({
              authState: middlewarePayload.auth,
              requestedUrl: Router.asPath,
            })
          if (next === undefined) return
          if (Router.asPath === next) {
            console.error(
              'Infinite redirect loop detected, allowing access to route as fail-safe',
            )
            return void useMiddlewareState
              .getState()
              .set({ asPath, pathname, result: 'ok' })
          }
          return void Router.replace(next)
        }

        return void useMiddlewareState
          .getState()
          .set({ asPath, pathname, result: 'ok' })
      } catch (error) {
        console.error(error)
        leaveBreadcrumb('MiddlewareProvider', {
          error,
          issue:
            'An error was thrown that triggered a middleware failure & full reload',
        })
        notify(error)
      }
    }
    fn()
  }, [
    auth.resolved,
    auth.data?.user.id,
    middlewares,
    router.asPath,
    router.pathname,
  ])

  const Wrapper = React.useMemo<MiddlewareWrapper>(() => {
    if (!middlewares) return React.Fragment
    return middlewares.reduce((Previous, middleware, ix) => {
      const Next = middleware.wrapper
      if (Next === undefined) return Previous
      const ReducedWrapper: MiddlewareWrapper = React.memo((props) => (
        <Previous>
          <Next {...props} />
        </Previous>
      ))
      ReducedWrapper.displayName = `MiddlewareWrapper(${
        Next.displayName || Next.name
      })<${ix}>`
      return ReducedWrapper
    }, React.Fragment as MiddlewareWrapper)
  }, [middlewares])

  const middlewareState = useMiddlewareState()
  const render = React.useMemo(() => {
    if (!middlewares || middlewareState.result === 'ok') return true
    return middlewares.every((middleware) => {
      return middleware.optimisticRender === true
    })
  }, [middlewareState.result, middlewares])

  return render ? (
    <MiddlewareContext.Provider value={middlewarePayload}>
      <Wrapper>{children}</Wrapper>
    </MiddlewareContext.Provider>
  ) : null
}
