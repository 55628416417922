import { ParsedFeatureFlags } from '@wise/kv'

import { PortalMode } from '~shared/hooks/useMode'
import { callLocalAPI } from '~shared/services/api/api'

import { BasePlatform } from '@/platforms/hooks/usePlatform'

type FeatureFlagOptions = {
  portalMode: PortalMode
  platform: BasePlatform | null
  user: {
    id: string
    email: string
  }
}

export const fetchFeatureFlags = async ({
  portalMode,
  platform,
  user,
}: FeatureFlagOptions): Promise<ParsedFeatureFlags> => {
  const search = new URLSearchParams({
    platformType: platform?.type ?? '',
    platformId: platform?.id ?? '',
    portalMode,
    userId: user.id,
    userEmail: user.email,
  }).toString()
  const response = await callLocalAPI<ParsedFeatureFlags>(
    `/api/feature-flags?${search}`,
  )

  if (!response.ok) {
    throw response.error
  }

  return response.data as ParsedFeatureFlags
}
