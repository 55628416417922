import { RoutePaths, route } from '~generated/routes'
import { AuthUserData } from '~shared/services/firebase/auth/types'

export const needsOnboardingRoute = (
  mcOnboardingData:
    | BrandExtract<AuthUserData, 'main-contractor'>['mcOnboardingData']
    | undefined,
): RoutePaths | null => {
  // MainContractor has been made active so they cannot see the onboarding journey
  if (mcOnboardingData?.mainContractor?.status === 'ACTIVE') return null

  // Onboarding MC has created an account but has yet to provide company details which
  // is the step that will create on the BE a MainContractor against the onboarding MC
  if (mcOnboardingData?.id && !mcOnboardingData.mainContractor) {
    return route('/onboarding/about-wise')
  }

  // Onboarding MC has now a MainContractor associated to them, if the MainContractor does not have a signature
  // the onboarding journey needs to be completed, if they have a signature then direct the user to the Limited Dashboard View
  if (mcOnboardingData?.mainContractor?.id) {
    if (!mcOnboardingData?.mainContractor.signature?.id) {
      return route('/onboarding/protecting-your-business')
    }
    return route('/')
  }

  return null
}
