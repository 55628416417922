import { uniq } from 'lodash'

import { PORTAL_RUNTIMES, PortalRuntime } from '~shared/config/app'
import { AuthUserDataType } from '~shared/services/firebase/auth/types'

const PORTAL_RUNTIME_GROUPS: Record<PortalRuntime, AuthUserDataType[]> = {
  MAINCONTRACTOR: ['wise', 'main-contractor'],
  NETWORK: ['wise', 'network'],
}

// What user types are allowed to login to the portal?
export const ALLOWED_USER_TYPES = PORTAL_RUNTIMES.reduce<AuthUserDataType[]>(
  (prev, runtime) => {
    return uniq([...prev, ...PORTAL_RUNTIME_GROUPS[runtime]])
  },
  [],
)
