import { SSOProvider } from '@/single-sign-on/utils/provider'

export const firebaseProviderIdToSSOProvider = (
  providerId: string,
): SSOProvider | null => {
  switch (providerId) {
    case 'google.com':
      return SSOProvider.Google
    case 'microsoft.com':
      return SSOProvider.Microsoft
    case 'password':
      return SSOProvider.Password
    default:
      return null
  }
}
