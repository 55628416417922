import { hasActiveOnboardingConfigurations } from '~shared/middleware/middlewares/hasActiveOnboardingConfigurations'
import { hasUploadedSignature } from '~shared/middleware/middlewares/hasUploadedSignature'
import { notAllowed } from '~shared/middleware/middlewares/notAllowed'

import { OnboardingStageEnum } from '@/mc-onboarding/middleware/type'

import { auth } from './auth'
import { featureFlag } from './featureFlags'
import { gatekeeper } from './gatekeeper'
import { guest } from './guest'
import { mcHasDeductionsEnabled } from './mcHasDeductionsEnabled'
import { mcOnboarding } from './mcOnboarding'
import { mcStatus } from './mcStatus'
import { mode } from './mode'
import { modulr } from './modulr'
import { permission } from './permission'

export const M = {
  auth,
  guest,
  modulr,
  mcOnboarding,
  permission,
  mcStatus,
  gatekeeper,
  featureFlag,

  WAP: mode({ mode: 'WAP' }),
  MCP: mode({ mode: 'MCP' }),
  NAP: mode({ mode: 'NAP' }),

  Guest: guest(),
  Authenticated: auth(),

  Permissions: {
    Wise: {
      Customer: {
        Get: permission({ permission: ['wise.customer.get'] }),
        List: permission({ permission: ['wise.customer.list'] }),
      },
      MainContractor: {
        Get: permission({ permission: ['wise.mainContractor.get'] }),
        List: permission({ permission: ['wise.mainContractor.list'] }),
      },
    },
    Own: {
      MainContractor: {
        Get: permission({ permission: ['own.mainContractor.get'] }),
      },
    },
    User: {
      List: permission({ permission: ['*.user.list'] }),
    },
  },

  MainContractor: {
    Active: mcStatus({ status: 'ACTIVE' }),
    Onboarding: mcStatus({ status: 'ONBOARDING' }),
  },

  Modulr: {
    Default: modulr(),
  },

  MCOnboarding: {
    HasUploadedSignature: hasUploadedSignature(),
    HasActiveOnboardingConfigurations: hasActiveOnboardingConfigurations(),
    Stages: {
      CreateAccount: mcOnboarding({ stage: OnboardingStageEnum.CreateAccount }),
      SetupCompany: mcOnboarding({ stage: OnboardingStageEnum.SetupCompany }),
      Contracts: mcOnboarding({ stage: OnboardingStageEnum.Contracts }),
    },
  },

  FeatureFlag: {
    BulkUploadEnabled: featureFlag({ featureFlag: 'bulkUploadEnabled' }),
    DeductionsEnabled: featureFlag({ featureFlag: 'deductionsEnabled' }),
    DrivingLicenceChecksEnabled: featureFlag({
      featureFlag: 'drivingLicenceChecksEnabled',
    }),
    IncidentsEnabled: featureFlag({ featureFlag: 'incidentsEnabled' }),
    NetworkPaymentsEnabled: featureFlag({
      featureFlag: 'networkPaymentsEnabled',
    }),
    SchedulingEnabled: featureFlag({ featureFlag: 'schedulingEnabled' }),
    SSOEnabled: featureFlag({ featureFlag: 'ssoEnabled' }),
    WorklogEnabled: featureFlag({ featureFlag: 'worklogEnabled' }),
  },

  MCHasDeductionsEnabled: {
    Default: mcHasDeductionsEnabled(),
    AllowedOnWap: mcHasDeductionsEnabled({ allowedOnWap: true }),
  },

  NotAllowed: notAllowed(),
}

export default M
