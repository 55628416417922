import { isNonEmptyString } from '@wise/utils'

import { isBranded } from '~shared/utils/brand'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions

export const hasUploadedSignature = (options?: Options): MiddlewareHandler => ({
  fn: async ({ auth, mainContractor, mode }) => {
    const onboardingData =
      auth.data?.user && isBranded(auth.data.user, 'main-contractor')
        ? auth.data.user.mcOnboardingData
        : null

    return mode === 'WAP' ||
      mainContractor?.status === 'ACTIVE' ||
      isNonEmptyString(onboardingData?.mainContractor?.signature?.id)
      ? { status: 'ok' }
      : { status: 'redirect' }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: undefined,
})
