import * as React from 'react'

import { UserPermission } from '~shared/permissions/enum'
import { AuthUserData } from '~shared/services/firebase/auth/types'
import { isBranded } from '~shared/utils/brand'

import { AuthContext } from './context'

export const useAuth = () => React.useContext(AuthContext)

export const useProviderInfo = () => useAuth().data?.providerInfo
export const useUser = () => useAuth().data?.user

export const useUserPermissions = (): UserPermission[] | null =>
  useUser()?.userPermissions ?? null

export const useMCOnboardingData = (): Mandatory<
  BrandExtract<AuthUserData, 'main-contractor'>['mcOnboardingData']
> | null => {
  const user = useUser()
  return user && isBranded(user, 'main-contractor')
    ? user.mcOnboardingData
    : null
}
