import { RoutePaths } from '~generated/routes'
import { PortalMode } from '~shared/hooks/useMode'
import { Permission } from '~shared/permissions/types'
import { AuthStore } from '~shared/services/firebase/auth/store'

import { MainContractorFromUser } from '@/maincontractors/hooks/useMaincontractor'
import { ModulrPaymentInfo } from '@/payments/hooks/useModulrPayments'

export type MiddlewareStatus =
  | { status: 'ok' }
  | { status: 'loading' }
  | { status: 'redirect'; url?: string }
export type MiddlewareResponse = Exclude<
  MiddlewareStatus,
  { status: 'loading' }
>

export type MiddlewareWrapper = React.ComponentType<{
  children: React.ReactNode
}>

export type MiddlewarePayload = {
  auth: AuthStore
  mode: PortalMode
  permissions: Permission[]
  mainContractor: MainContractorFromUser
  modulrInfo: ModulrPaymentInfo
  mainContractorId: string | undefined
}
export type MiddlewareHandler = {
  fn: (
    payload: MiddlewarePayload,
  ) => Promise<MiddlewareResponse> | MiddlewareResponse
  optimisticRender?: boolean
  wrapper?: MiddlewareWrapper
}

export type MiddlewareHandlerV2 = { type: 'v2' } & Partial<
  Record<PortalMode, MiddlewareHandler | MiddlewareHandler[]>
>

export type MiddlewareMapValue =
  | MiddlewareHandler
  | MiddlewareHandler[]
  | MiddlewareHandlerV2
  | null
export type MiddlewareMap = Record<RoutePaths, MiddlewareMapValue>

export type DefaultOptions = Pick<
  MiddlewareHandler,
  'optimisticRender' | 'wrapper'
> & { redirectTo?: string }

export const isMiddlewareHandlerV2 = (
  item: MiddlewareMapValue,
): item is MiddlewareHandlerV2 =>
  item !== null && 'type' in item && item.type === 'v2'
