import { CheckOnboardingInviteCodeDocument } from '@wise/graphql'
import { invariant, isDefined, isNonEmptyString } from '@wise/utils'
import Router from 'next/router'

import { rootApolloClient } from '~shared/services/apollo/apollo'
import { isBranded } from '~shared/utils/brand'

import { OnboardingStageEnum } from '@/mc-onboarding/middleware/type'

import { DefaultOptions, MiddlewareHandler } from '../../types'

import useMcOnboardingStore from './store'

type Options = Omit<DefaultOptions, 'wrapper'> & { stage: OnboardingStageEnum }

export const mcOnboarding = (options?: Options): MiddlewareHandler => ({
  fn: async (ctx) => {
    try {
      const user = ctx.auth.data?.user
      const onboardingData =
        user && isBranded(user, 'main-contractor')
          ? user.mcOnboardingData
          : null
      const mainContractor = onboardingData?.mainContractor

      invariant(options?.stage, 'Stage is required')

      switch (options.stage) {
        case OnboardingStageEnum.CreateAccount: {
          const code = Router.query.code || useMcOnboardingStore.getState().code
          invariant(
            isNonEmptyString(code),
            'Your invite code was missing or malformed.',
          )
          const { email, firstName, lastName } = (
            await rootApolloClient.query({
              query: CheckOnboardingInviteCodeDocument,
              variables: { code },
            })
          ).data.checkInviteCode
          invariant(isNonEmptyString(email), 'Your invite code was invalid.')
          invariant(isNonEmptyString(firstName), 'First name is missing')
          invariant(isNonEmptyString(lastName), 'Last name is missing')

          // Put the code, email, firstName & lastName in our store
          useMcOnboardingStore.getState().setInitialOnboardingMC({
            code,
            email,
            firstName,
            lastName,
          })

          return { status: 'ok' }
        }
        case OnboardingStageEnum.SetupCompany: {
          invariant(isDefined(onboardingData), 'No onboarding data found.')
          invariant(
            !isDefined(mainContractor),
            'Main contractor already setup.',
          )
          return { status: 'ok' }
        }
        case OnboardingStageEnum.Contracts: {
          // Needs onboarding data, with maincontractor, but NO signature
          invariant(isDefined(onboardingData), 'No onboarding data found.')
          invariant(isDefined(mainContractor), 'Main contractor not setup.')
          invariant(
            !isNonEmptyString(mainContractor?.signature?.id),
            'Main contractor already setup contracts and their signature.',
          )
          return { status: 'ok' }
        }
        default:
          return { status: 'ok' }
      }
    } catch (error) {
      return { status: 'redirect' }
    }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: undefined,
})
