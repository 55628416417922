import { djs } from './date'

type TimeOfDay = 'login.morning' | 'login.afternoon' | 'login.evening'

/**
 * 5am -> 12pm is morning
 * 12pm -> 6pm is afternoon
 * 6pm -> 5am is evening
 * @param time The current time (defaults to now)
 */
export const timeOfDay = (time = new Date()): TimeOfDay => {
  const hour = time.getHours()

  // 4am -> 12pm is morning
  if (hour >= 4 && hour < 12) return 'login.morning'

  // 12pm -> 5pm is afternoon
  if (hour >= 12 && hour < 17) return 'login.afternoon'

  // 5pm -> 4am is evening
  return 'login.evening'
}

export const getTimezone = (): number => new Date().getTimezoneOffset()

export const hourMinToLabel = (hour: number, min: number): string => {
  const suffix = hour >= 12 ? 'pm' : 'am'
  if (hour >= 12) hour - 12
  return `${hour === 0 ? 12 : hour > 12 ? hour - 12 : hour}:${String(
    min,
  ).padStart(2, '0')}${suffix}`
}

type OptionType = { value: string; label: string }
type OptionList = OptionType[]
export const getTimeOfDayOptions = (
  minuteGap = 5,
  startFrom = 0,
  endAt = -1,
): { value: string; label: string }[] => {
  const list: OptionList = []

  for (let minTotal = 0; minTotal < 60 * 24; minTotal += minuteGap) {
    const actualMin = minTotal + startFrom
    if (endAt !== -1 && actualMin > endAt) break
    const hour = Math.floor(actualMin / 60) % 24
    const min = actualMin % 60
    const option: OptionType = {
      value: `${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}`,
      label: hourMinToLabel(hour, min),
    }
    list.push(option)
  }
  return list
}

export const getTimeInTimePickerFormat = (
  dateString: string | null | undefined,
): string | null => {
  if (!dateString) return null

  //if utc is not specified, dayjs returns the hours wrong if date is during DTS
  const date = djs(dateString).utc()

  if (!date.isValid()) return null

  const [hour, min] = [date.get('hours'), date.get('minutes')]

  return `${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}`
}

export const toZeroTimeUTCDate = (originalValue: Date) => {
  return new Date(
    Date.UTC(
      originalValue.getFullYear(),
      originalValue.getMonth(),
      originalValue.getDate(),
      0,
      0,
      0,
    ),
  )
}
