import { isNonEmptyString } from '@wise/utils'
import NextHead from 'next/head'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { PORTAL_RUNTIMES } from '~shared/config/app'
import { PortalMode, useMode } from '~shared/hooks/useMode'

interface Props {
  title?: string
}

const Head: React.FC<Props> = ({
  title: customTitle,
  children,
}): JSX.Element => {
  const mode = useMode()
  const { t } = useTranslation()

  const title = React.useMemo(() => {
    const isNetwork = PORTAL_RUNTIMES.includes('NETWORK')

    const effectiveMode: PortalMode = isNetwork ? 'NAP' : mode

    const title = t(
      mode === 'undetermined' ? 'generic.loading' : `portal.${effectiveMode}`,
      'Loading...',
    )

    return [customTitle, title].filter(isNonEmptyString).join(' | ')
  }, [customTitle, mode, t])

  return (
    <NextHead>
      <title key='title'>{title}</title>
      {children}
    </NextHead>
  )
}

export default Head
