export const supportedFeatureFlagNames = [
  'allowRejectingOnboardingEvidence',
  'bulkUploadEnabled',
  'deductionsEnabled',
  'drivingLicenceChecksEnabled',
  'incidentsEnabled',
  'locationEditingEnabled',
  'networkPaymentsEnabled',
  'schedulingEnabled',
  'ssoEnabled',
  'worklogEnabled',
] as const
