import {
  GetInitialUserDataDocument,
  GetInitialUserDataQuery,
} from '@wise/graphql'
import { isDefined, isNonEmptyString, maybe } from '@wise/utils'

import { FALLBACK_FEATURE_FLAGS } from '~shared/config/feature-flags'
import { ALLOWED_USER_TYPES } from '~shared/consts/auth'
import { fetchFeatureFlags } from '~shared/featureFlags/api'
import { PortalMode } from '~shared/hooks/useMode'
import { setAnalyticsUser } from '~shared/services/analytics/analytics'
import { setApiMetadata } from '~shared/services/api/api'
import { getApolloClient } from '~shared/services/apollo/apollo'
import { notify, setUser } from '~shared/services/bugsnag/client'
import { FirebaseUser } from '~shared/services/firebase/auth/Authenticator'
import { firebaseProviderIdToSSOProvider } from '~shared/services/firebase/auth/providers'
import { AuthData } from '~shared/services/firebase/auth/store'
import {
  AuthUserData,
  BaseUserData,
} from '~shared/services/firebase/auth/types'

import { BasePlatform } from '@/platforms/hooks/usePlatform'
import { getDriverName } from '@/subcontractors/tables/helpers'

const getAuthUser = (result: GetInitialUserDataQuery): AuthUserData | null => {
  switch (result.me.user.__typename) {
    // These Network users are resolved via the Persona service, and can include different fields from the normal User type
    case 'NetworkUser':
      return {
        __brand: 'network',
        email: result.me.user.email,
        firstName: result.me.user.firstName,
        id: result.me.user.userId,
        lastName: result.me.user.userLastName,
        permissions: [],
        roles: [],
        userPermissions: result.me.user.userPermissions ?? [],
        network: result.me.user.network,
      }
    case 'User':
      {
        const baseUserData: BaseUserData = {
          email: result.me.user.email,
          firstName: result.me.user.firstName,
          id: result.me.user.id,
          lastName: result.me.user.lastName ?? '',
          permissions: result.me.user.permissions?.filter(isDefined) ?? [],
          roles: result.me.user.roles?.filter(isDefined) ?? [],
          userPermissions: result.me.user.userPermissions ?? [],
        }

        switch (result.me.user.type) {
          case 'NETWORK':
            throw new Error(
              'Invalid user type - Network user cannot be resolved via the "User" __typename!',
            )
          case 'MAINCONTRACTOR': {
            const mainContractor:
              | BrandExtract<AuthUserData, 'main-contractor'>['mainContractor']
              | null =
              result.me.user.gigs?.find((g) => g?.mainContractor)
                ?.mainContractor ?? null

            const mcOnboardingData = result.getMcOnboardingData ?? null

            return {
              ...baseUserData,
              __brand: 'main-contractor',
              mainContractor,
              mcOnboardingData,
            }
          }
          case 'WISE':
            return {
              ...baseUserData,
              __brand: 'wise',
            }
        }
      }

      return null
  }
}

const userBrandToPortalMode = (brand: AuthUserData['__brand']): PortalMode => {
  switch (brand) {
    case 'main-contractor':
      return 'MCP'
    case 'network':
      return 'NAP'
    case 'wise':
      return 'WAP'
  }
}

const authUserDataToPlatform = (user: AuthUserData): BasePlatform | null => {
  switch (user.__brand) {
    case 'main-contractor': {
      const id = user.mainContractor?.id
      if (!isNonEmptyString(id)) return null
      return { type: 'main-contractor', id }
    }
    case 'network':
      return { type: 'network', id: user.network.id }
    case 'wise':
    default:
      return null
  }
}

export const resolveAuthData = async (
  firebaseUser: FirebaseUser | null,
): Promise<AuthData | null> => {
  try {
    if (!firebaseUser) return null

    const result = await getApolloClient().query({
      query: GetInitialUserDataDocument,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    })

    if (!result.data) return null

    const accessToken = await firebaseUser.getIdToken()

    const user = getAuthUser(result.data)

    setUser(user?.id, user?.email, user ? getDriverName(user) : undefined)
    setAnalyticsUser(user)
    setApiMetadata(user)

    if (!user) return null

    // Don't allow login to proceed if wrong user type
    const isAllowedInRuntime = ALLOWED_USER_TYPES.includes(user.__brand)
    if (!isAllowedInRuntime)
      throw new Error(
        `User type "${user.__brand}" is not allowed in this runtime`,
      )

    const featureFlags = await maybe(() =>
      fetchFeatureFlags({
        portalMode: userBrandToPortalMode(user.__brand),
        platform: authUserDataToPlatform(user),
        user: {
          id: user.id,
          email: user.email,
        },
      }),
    )

    const providerData = firebaseUser.providerData[0]

    return {
      providerInfo: {
        email: providerData.email ?? null,
        name: providerData.displayName ?? null,
        avatarUrl: providerData.photoURL ?? null,
        provider: firebaseProviderIdToSSOProvider(providerData.providerId),
      },
      accessToken,
      featureFlags: featureFlags ?? FALLBACK_FEATURE_FLAGS,
      user,
    }
  } catch (error) {
    if (firebaseUser) {
      notify(
        new ResolveAuthDataError(firebaseUser.email ?? firebaseUser.uid, error),
      )
    }
    throw error
  }
}

export class ResolveAuthDataError extends Error {
  constructor(email: string, error: unknown) {
    super(
      `${email} (${error instanceof Error ? error.message : String(error)})`,
      { cause: error },
    )
    this.name = 'ResolveAuthDataError'
  }
}
