import { isNonEmptyString } from '@wise/utils'
import * as React from 'react'

import { useUser } from '~shared/services/firebase/auth/hooks'
import { AuthUserData } from '~shared/services/firebase/auth/types'

type MainContractor = {
  type: 'main-contractor'
  mainContractor: Extract<
    AuthUserData,
    { __brand: 'main-contractor' }
  >['mainContractor']
}
type Network = {
  type: 'network'
  network: Extract<AuthUserData, { __brand: 'network' }>['network']
}

export type Platform = { id: string } & (MainContractor | Network)
export type PlatformType = Platform['type']
export type BasePlatformFields = 'id' | 'type'
export type BasePlatform = Pick<Platform, BasePlatformFields>

export type NetworkPlatform = Extract<Platform, { type: 'network' }>
export type MainContractorPlatform = Extract<
  Platform,
  { type: 'main-contractor' }
>

export type BaseNetworkPlatform = Pick<NetworkPlatform, BasePlatformFields>
export type BaseMainContractorPlatform = Pick<
  MainContractorPlatform,
  BasePlatformFields
>

export const usePlatform = (): Platform | null => {
  const user = useUser()

  return React.useMemo<Platform | null>(() => {
    if (!user || user.__brand === 'wise') return null
    switch (user.__brand) {
      case 'main-contractor': {
        const id = user.mainContractor?.id
        if (!isNonEmptyString(id)) return null
        return {
          type: 'main-contractor',
          id,
          mainContractor: user.mainContractor,
        }
      }
      case 'network':
        return {
          type: 'network',
          id: user.network.id,
          network: user.network,
        }
    }
  }, [user])
}

export function useConstructPlatform(
  type: 'main-contractor',
  id: unknown,
): BaseMainContractorPlatform | null
export function useConstructPlatform(
  type: 'network',
  id: unknown,
): BaseNetworkPlatform | null
export function useConstructPlatform(
  type: BasePlatform['type'],
  id: unknown,
): BasePlatform | null {
  return React.useMemo<BasePlatform | null>(() => {
    if (!isNonEmptyString(id)) return null
    return { type, id }
  }, [id, type])
}
